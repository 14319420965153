.br_poi_content_manager_mtr {
    margin-top: 1rem;
}

.br_poi_content_manager_Modal {
    max-width: 1000px !important;
}

.br_poi_content_manager_lang {
    width: 50%;
}

.br_poi_content_manager_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.br_poi_content_manager_photo {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.br_poi_content_manager_upload {
    width: 104px;
    height: 104px;
}

.br_poi_content_manager_uploaButton {
    width: 104px;
    height: 104px !important;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
    text-align: center;
    border-radius: 4px;
    vertical-align: top;
}

.br_poi_content_manager_vicinity {
    padding-left: 2rem;
    margin-top: 1rem;
}

.marker-label {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transform: translateY(23px);
    /* Adjust the translateY value as needed */
}

.br_poi_content_manager_approve {
    padding: 1.5rem;
}

.br_poi_content_manager_mapPin {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color);
}

.br_poi_content_manager_edit {
    cursor: pointer;
    font-size: 1rem;
    color: var(--primary--color);
}

.br_poi_content_manager_listTItle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
}

.br_poi_content_manager_card {
    max-height: 58vh;
    overflow-y: auto;
}

.br_poi_content_manager_cardFooter {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.contributions_pending_icon {
    color: var(--pending--color);
    font-size: 1.2rem;
    margin-right: 0.6rem;
}

.contributions_approved_icon {
    color: var(--approved--color);
    font-size: 1.2rem;
    margin-right: 0.6rem;
}

.contributions_declined_icon {
    color: var(--declined--color);
    font-size: 1.2rem;
    margin-right: 0.6rem;
}

.br_poi_content_manager_listItem {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.br_poi_content_manager_listGroup {
    height: 53vh;
    overflow-y: auto;
    font-size: 0.9rem;
}

.br_poi_content_manager_trash {
    color: var(--declined--color);
    cursor: pointer;
    font-size: 1.3rem;
    margin-right: 15px;
}

.br_poi_content_manager_back {
    color: var(--busy--color);
    cursor: pointer;
    font-size: 1.3rem;
}

.br_poi_content_manager_p {
    font-size: 0.9rem;
}

.br_poi_content_manager_span {
    font-size: 0.9rem;
    margin: 0px;
    display: flex;
    align-items: center;
}

.vertical-tabs-container {
    float: left;
}

.tablinks:hover {
    background-color: #ddd;
}

.vertical-tab {
    margin-bottom: 10px;
}

.vertical-tab button {
    width: 150px;
    padding: 10px;
    text-align: left;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    cursor: pointer;
}

.tablinks:active {
    background-color: #007bff;
    color: #fff;
}

.vertical-tabs-content {
    float: left;
    padding: 0px 12px;
    width: 70%;
    border-left: none;
}

.vertical-tabcontent {
    display: none;
}

.vertical-tabcontent.active {
    display: block;
}

.active-tab .nav-link.active {
    color: #007bff;
}

.active-tab .nav-link {
    color: #495057;
}

.tab-container {
    display: flex;
}

.tabs {
    width: 150px;
    background-color: #f2f2f2;
}

.tab {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    font-size: 0.9rem;
}

.tab.active {
    background-color: #bfdfff;
}

.contributions {
    padding: 10px;
}

.contributions ul {
    list-style-type: none;
    padding: 0;
}

.contributions li {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.contributions li:last-child {
    border-bottom: none;
}

.contributionDetailIcons {
    color: #1a73e8;
    font-size: 1rem;
    margin-right: 0.5rem;
}

.tab-title {
    display: flex;
    align-items: center;
}

.additional-icons {
    display: flex;
    margin-left: auto;
}

.additional-icons .icon {
    margin-left: 0.5rem;
}

.tabs-right-icon {
    position: absolute;
    right: 1vw;
}

.tabBackIcon {
    color: #000000;
    font-size: 1.3rem;
    margin-right: 1rem;
}

.tabApproveIcon {
    color: var(--approved--color);
    font-size: 1.3rem;
    margin-right: 1rem;
}

.tabRejectIcon {
    color: var(--declined--color);
    font-size: 1.3rem;
    margin-right: 1rem;
}

.poiManagerTabs .nav-link.active {
    color: #007bff;
}

.poiManagerTabs .nav-link {
    color: #000000;
}

.htmlRendered {
    font-size: 0.9rem !important;
    font-family: "Open Sans", sans-serif !important;
    line-height: 1.1rem !important;
}

.poiInfoWindowText {
    font-size: 0.9rem !important;
    font-family: "Open Sans", sans-serif !important;
}

.infoWindowTitle {
    font-weight: bold;
}

.poiTabIcon {
    font-size: 1.1rem;
    margin-bottom: 0.1rem;
    margin-right: 8px;
}